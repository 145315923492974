import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Spin,
  Layout,
  Typography,
  Form,
  Input,
  Button,
  Select,
  Modal,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import api from '../../service/api';
import * as S from './styles';
import InputMask from 'react-input-mask';

const { Option } = Select;
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 20 },
};

const { Title } = Typography;
const { Content } = Layout;

export type SaveNewClientForm = {
  id: string;
  empresa: string;
  cnpj: string;
  administradora: string;
  campo1: string;
  campo2: string;
  campo3: string;
};

interface MyRecord {
  id: string;
  empresa: string;
  cnpj: string;
  administradora: string;
  campo1: string;
  campo2: string;
  campo3: string;
}

interface LocationState {
  record: MyRecord | null;
}

const CadastroPortalAdministradoras: React.FC = () => {
  const [isSpinning, setIsSpinning] = useState(false);
  const [empresas, setEmpresas] = useState<Array<{ cnpj: string; empresa: string }>>([]);
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { record } = location.state || { record: null };
  const [form] = Form.useForm();

  useEffect(() => {
    if (record) {
      form.setFieldsValue(record);
    }
  }, [record, form]);

  const fetchEmpresas = async () => {
    setIsSpinning(true);
    try {
      const clienteJSON = localStorage.getItem('cliente');
      if (!clienteJSON) {
        toast.error('Cliente não encontrado no localStorage');
        return;
      }

      const cliente = JSON.parse(clienteJSON);
      const nomeDoGrupo = cliente.nomeDoGrupo || "";

      const response = await api.get(`/api/LoginPortal/empresasCNPJ`, {
        params: { groupName: nomeDoGrupo },
      });

      if (response.data && response.data.result && response.data.result.length > 0) {
        setEmpresas(response.data.result);
      } else {
        toast.error('Nenhuma empresa encontrada.');
      }
    } catch (error) {
      console.error('Erro ao carregar as empresas:', error);
      toast.error('Erro ao carregar as empresas');
    } finally {
      setIsSpinning(false);
    }
  };

  useEffect(() => {
    fetchEmpresas();
  }, []);

  const handleEmpresaChange = (value: string) => {
    const selectedEmpresa = empresas.find(empresa => empresa.empresa === value);

    if (selectedEmpresa) {
      form.setFieldsValue({ cnpj: selectedEmpresa.cnpj });
    }
  };

  const removeFormatting = (value: string) => {
    return value.replace(/\D/g, ''); // Remove tudo que não é número
  };

  const onFinish = async (values: SaveNewClientForm) => {
    console.log('Dados recebidos do formulário:', values);

    // Limpa a formatação do CNPJ
    const formattedValues = {
      ...values,
      cnpj: removeFormatting(values.cnpj),
    };

    const clienteData = {
      Id: record?.id || '',
      ...formattedValues,
      NomeGrupo: '',
    };

    setIsSpinning(true);
    try {
      const clienteJSON = localStorage.getItem('cliente');
      if (clienteJSON) {
        const cliente = JSON.parse(clienteJSON);
        clienteData.NomeGrupo = cliente.nomeDoGrupo;

        const response = record?.id
          ? await api.put(`/api/LoginPortal/updatePortalAdministradoras`, clienteData)
          : await api.post('/api/LoginPortal/createPortalAdministradoras', clienteData);

        handleBack();
        // if (response.data.success) {
        //   Modal.success({
        //     title: 'Sucesso!',
        //     content: response.data.message,
        //   });
        //   handleBack();
        // } else {
        //   Modal.error({
        //     title: 'Erro!',
        //     content: response.data.message,
        //   });
        // }
      }
    } catch (error) {
      console.error('Erro inesperado:', error);
      toast.error('Um erro inesperado aconteceu.');
    } finally {
      setIsSpinning(false);
    }
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 34, color: "#2ce414" }} spin />;
  const handleBack = () => {
    const storedUser = localStorage.getItem('cliente');
    if (storedUser) {
      const clienteObject = JSON.parse(storedUser);
      if (clienteObject && clienteObject) {
        history.push(`/ListaPortalAdministradoras/${clienteObject.id}`);
      }
    }
  };

  return (
    <Spin indicator={antIcon} spinning={isSpinning}>
      <Layout className="layout">
        <Content style={{
          backgroundColor: "white",
          padding: '50px 50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100vh',
        }}>
          <S.SiteLayoutContent>
            <S.ContainerTitle>
              <Title level={3}>Cadastro</Title>
            </S.ContainerTitle>
            <S.FormContainer>
              <Form
                {...layout}
                form={form}
                name="nest-messages"
                onFinish={onFinish}
              >
                <Form.Item
                  name="empresa"
                  label="EMPRESA"
                  rules={[{ required: true, message: 'Por favor, selecione uma empresa' }]}
                >
                  <Select placeholder="Selecione uma empresa" onChange={handleEmpresaChange}>
                    {empresas.length > 0 ? (
                      empresas.map(empresa => (
                        <Option key={empresa.cnpj} value={empresa.empresa}>
                          {empresa.empresa}
                        </Option>
                      ))
                    ) : (
                      <Option disabled>Nenhuma empresa encontrada</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="cnpj"
                  label="CNPJ"
                  rules={[{ required: true, message: 'Por favor, insira o CNPJ' }]}
                >
                  <InputMask mask="99.999.999/9999-99" readOnly={true}>

                  </InputMask>
                </Form.Item>
                <Form.Item
                  name='administradora'
                  label="ADMINISTRADORA"
                  rules={[{ required: true, message: 'Por favor insira o nome da administradora' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name='campo1'
                  label="CAMPO 1"
                  rules={[{ required: true, message: 'Por favor insira o campo 1' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name='campo2'
                  label="CAMPO 2"
                  rules={[{ required: true, message: 'Por favor insira o campo 2' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name='campo3'
                  label="CAMPO 3">
                  <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 24 }}>
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '15px' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={isSpinning}
                      style={{
                        backgroundColor: '#4CAF50',
                        borderColor: '#4CAF50',
                        color: 'white',
                        marginRight: '8px',
                      }}
                    >
                      Salvar
                    </Button>
                    <Button
                      type="default"
                      htmlType="button"
                      onClick={handleBack}
                      style={{
                        backgroundColor: '#f44336',
                        borderColor: '#f44336',
                        color: 'white',
                      }}
                    >
                      Voltar
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </S.FormContainer>
          </S.SiteLayoutContent>
        </Content>
      </Layout>
    </Spin>
  );
};

export default CadastroPortalAdministradoras;