// styles.ts
import styled from 'styled-components';

export const Tools = styled.div`
    width: 100%;
    margin-top: 5vh;
    margin-bottom: 3vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const divButton = styled.div`
    margin: 0 0 0 2rem;
`;

export const Button = styled.button`
    border: none;
    background-color: #70e000;
    border-radius: 6px;
    padding: 0.5rem 2rem;
    color: white;
    cursor: pointer;
    transition: all 0.3s;
    white-space: nowrap;
    &:hover {
        opacity: 0.7;
    }
`;

export const ContainerImage = styled.div`
  margin-left: 10%;
  width: fit-content;
  img {
    width: 160px;
  }
  span {
    margin-left: 20px;
    font-size: 0.8rem;
    font-weight: 700;
  }
`;

export const SearchContainer = styled.form`
    display: flex;
    margin: 0;
    
    svg {
        width: 30px;
    }

    @media (max-width: 500px) {
        margin: 0 2rem 0 auto;
    }
`;

export const DivTable = styled.div`
    height: 610px;
    width: 90%;
    margin: 0 2rem 0 2rem;

    .ant-btn {
        /* Se precisar de algum estilo específico para botões, adicione aqui */
    }

    .ant-table-cell {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const ContainerWord = styled.div`
  margin-right: auto;
  margin-left: auto;
  width: fit-content;
  font-weight: 700;
  font-size: 24px;
`;

export const MenuContainer = styled.div`
  select {
    padding: 8px 12px; /* Padding confortável */
    font-size: 16px; /* Tamanho de fonte legível */
    border-radius: 4px; /* Borda arredondada */
    border: 1px solid #dcdcdc; /* Cor do contorno padrão, um tom de cinza claro */
    background-color: #ffffff; /* Cor de fundo branca */
    color: #333333; /* Cor do texto */
    outline: none; /* Remove o contorno padrão do navegador */
    cursor: pointer; /* Cursor de ponteiro para interação */
    transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Transição suave para borda e sombra */
    
    &:focus {
      border-color: #007bff; /* Cor azul do contorno quando em foco */
      box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25); /* Sombra azul ao redor do contorno */
    }

    /* Remove a seta padrão do select em alguns navegadores */
    -webkit-appearance: none; /* Para Webkit (Chrome, Safari) */
    -moz-appearance: none; /* Para Firefox */
    appearance: none; /* Para outros navegadores */

    /* Remove borda padrão de botões e indicadores em navegadores Webkit e Edge */
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator,
    &::-ms-expand {
      display: none; /* Remove botões e indicadores extras */
    }

    /* Remove bordas e fundo padrão das opções em navegadores Webkit e Firefox */
    &::-webkit-dropdown-list,
    &::-moz-focus-inner {
      background-color: #ffffff; /* Define o fundo das opções como branco */
      border: 0; /* Remove a borda interna no foco */
      padding: 0; /* Remove o padding interno */
    }
  }
`;