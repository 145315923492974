import styled from 'styled-components'
import { Button } from 'antd';

export const SiteLayoutContent = styled.div`
  min-height: 280px;
  padding: 40px 30px 24px 24px;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%; /* Ocupa 100% da largura por padrão */
  
  @media (min-width: 768px) {
    width: 80%; /* Aumenta a largura em telas médias */
  }
  
  @media (min-width: 992px) {
    width: 60%; /* Reduz a largura em telas maiores */
  }

  @media (min-width: 1200px) {
    width: 50%; /* Mantém a largura mais reduzida em telas grandes */
  }
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 100vh;
`
export const HideBar = styled.div`
  z-index: 1000;
  height: 60px;
  transform: translateY(-54px);
  background-color: white;
`
export const ContainerTitle = styled.div`
  text-align: center;
  margin-bottom: 30px;
`

export const FormContainer = styled.div`
  .ant-form-item-label {
    text-align: left;
  }
  .ant-form-item-control {
    text-align: left;
  }
  .ant-form-item {
    margin-left: 20px; /* Margem menor por padrão */
  }

  @media (min-width: 768px) {
    .ant-form-item {
      margin-left: 40px; /* Aumenta a margem em telas maiores */
    }
  }
`;

export const MenuContainer = styled.div`
  span {
    margin-left: 10px; /* Ajusta a margem do menu */
  }

  @media (min-width: 768px) {
    span {
      margin-left: 20px;
    }
  }
`;

// Estilo para o botão "salvar"
export const SaveButton = styled(Button)`
  background-color: #4CAF50;
  border-color: #4CAF50;
`;

// Estilo para o botão "voltar"
export const BackButton = styled(Button)`
  background-color: #f44336;
  border-color: #f44336;
`;